<template>
  <Layout>
    <PageHeader :items="items" />
    <!--FILTER START------>
    <div class="card" style="margin-top: -30px">
      <div class="card-body">
        <div class="inner mb-2 row">
          <!-- Country List-->
          <div class="col-md-2" style="width: 12%">
            <label>Country </label>
            <multiselect
              v-model="country"
              :options="countryArr"
              :show-labels="false"
              label="country"
              track-by="country"
              @input="getAllCities()"
            ></multiselect>
          </div>
          <!-- Country List END-->
          <!-- Company List-->
          <!--          <div-->
          <!--            v-if="-->
          <!--              this.$storageData.profile.empTypeID == 1 ||-->
          <!--                this.$storageData.profile.empTypeID == 11-->
          <!--            "-->
          <!--            class="col-md-2"-->
          <!--            style="width: 16%"-->
          <!--          >-->
          <!--            <label>Company</label>-->
          <!--            <multiselect-->
          <!--              v-model="companyID"-->
          <!--              :options="companyArr"-->
          <!--              :show-labels="false"-->
          <!--              label="companyName"-->
          <!--              track-by="companyID"-->
          <!--              @input="getRestaurantBrandsByCompanyID()"-->
          <!--            ></multiselect>-->
          <!--          </div>-->
          <!-- Compnay List END-->
          <!-- Brand List-->
          <div class="col-md-2">
            <label>Select City </label>
            <multiselect
              v-model="city"
              :options="cityArr"
              :show-labels="false"
              label="city"
              track-by="city"
              @input="getPrincipleAndCorporates()"
            ></multiselect>
          </div>
          <div v-if="login_type != 3" class="col-md-4">
            <label>Select Principle Branch </label>
            <multiselect
              v-model="principleBranchID"
              :options="principleBranches"
              :show-labels="false"
              label="principleBranchName"
              track-by="principleBranchID"
              @input="getRestaurantByPrincipleBranch"
            ></multiselect>
          </div>
          <div class="col-md-4">
            <label class="form-label">Select Corporate Branch </label>
            <multiselect
              v-model="corpBranchID"
              :options="corpBranches"
              :show-labels="false"
              label="corpBranchName"
              track-by="corpBranchID"
              @input="getRestaurantByCorporateBranch"
            ></multiselect>
            <!-- @input="onchangeBranch();" -->
          </div>
        </div>
        <div class="row mt-3">
          <!--          <div class="col-md-3">-->
          <!--            <label>Restaurant Brand</label>-->
          <!--            <multiselect-->
          <!--              v-model="brandData"-->
          <!--              :options="brandArr"-->
          <!--              :show-labels="false"-->
          <!--              label="name"-->
          <!--              track-by="restID"-->
          <!--            ></multiselect>-->
          <!--          </div>-->
          <!--           Brand List END-->
          <!-- Cities List-->
          <!--          <div-->
          <!--            v-if="-->
          <!--              this.$storageData.profile.empTypeID == 1 ||-->
          <!--                this.$storageData.profile.empTypeID == 11-->
          <!--            "-->
          <!--            class="col-md-2"-->
          <!--            style="width: 12%"-->
          <!--          >-->
          <!--            <label>City </label>-->
          <!--            <multiselect-->
          <!--              v-model="city"-->
          <!--              :options="cityArr"-->
          <!--              :show-labels="false"-->
          <!--              label="city"-->
          <!--              track-by="city"-->
          <!--              @input="readRestaurantBranchData()"-->
          <!--            ></multiselect>-->
          <!--          </div>-->
          <!--END Cities List-->
          <!-- Branch List-->
          <!--          <div-->
          <!--            v-if="-->
          <!--              this.$storageData.profile.empTypeID == 1 ||-->
          <!--                this.$storageData.profile.empTypeID == 11-->
          <!--            "-->
          <!--            class="col-md-2"-->
          <!--            style="width: 16%"-->
          <!--          >-->
          <!--            <label>Restaurant Branch </label>-->
          <!--            <multiselect-->
          <!--              v-model="branchID"-->
          <!--              :options="branches"-->
          <!--              :show-labels="false"-->
          <!--              label="branchName"-->
          <!--              track-by="restBranchID"-->
          <!--              @input="onchangeBranch()"-->
          <!--            ></multiselect>-->
          <!--          </div>-->
          <!-- Branch List END-->
          <!-- Date range -->
          <div class="col-md-3">
            <label class="form-label" for="form row-endTime-input"
              >Select Restaurant Branch*</label
            >
            <multiselect
              v-model="restBranchID"
              :class="{
                'is-invalid': submitted && $v.restBranchID.$error
              }"
              :options="restBranchArr"
              label="restaurantName"
              track-by="restBranchID"
            ></multiselect>
          </div>
          <div class="col-md-3">
            <label class="form-label" for="form row-endTime-input"
              >Select Day of Week</label
            >
            <multiselect
              v-model="day"
              :options="daysOfWeek"
              label="key"
              track-by="val"
            ></multiselect>
          </div>
          <div class="col-md-3">
            <label class="form-label" for="form row-endTime-input"
              >Select Session</label
            >
            <multiselect v-model="session" :options="sessions"></multiselect>
          </div>
          <!--        </div>-->
          <!--        <div class="row mt-3">-->
          <div class="col-md-3">
            <label>Date Range </label>
            <date-picker
              v-model="daterange"
              append-to-body
              confirm
              format="DD MMM Y"
              value-type="format"
              lang="en"
              range
            ></date-picker>
          </div>
          <div class="col-md-2" style="width: auto">
            <button class="btn btn-themeOrange mt-4" v-on:click="applyFilter()">
              Apply Filter
            </button>
          </div>
          <!-- Clear filter -->
          <div class="col-md-2" style="width: 16%">
            <button class="btn btn-themeBrown mt-4" v-on:click="clearFilter()">
              Clear Filter
            </button>
          </div>
          <!-- Clear filter END-->
        </div>
      </div>
    </div>
    <!--FILTER END------>

    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <b-tabs
            content-class="p-3 text-muted"
            justified
            nav-class="nav-tabs-custom"
          >
            <!--            <b-tab @click="getProductMixReport()">-->
            <b-tab active @click="getFeedbackReviewbyflag(3, 0)">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-home"></i>
                </span>
                <span class="d-none d-sm-inline-block">Feedback Overview</span>
              </template>

              <div class="row">
                <div class="col-md-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{ total_ratings }}
                        </h4>
                        <p class="text-muted mb-0">Total No. of Rating</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{ average_ratings }}
                        </h4>
                        <p class="text-muted mb-0">Average Rating</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{ total_reviews }}
                        </h4>
                        <p class="text-muted mb-0">No. Of Reviews</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{ ratings_equal_above_three }}
                        </h4>
                        <p class="text-muted mb-0">No. Of Ratings 3 & Above</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{ ratings_below_three }}
                        </h4>
                        <p class="text-muted mb-0">No. Of Ratings Below 3</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{ ratings_by_male }}
                        </h4>
                        <p class="text-muted mb-0">No. Of Ratings By Male</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{ ratings_by_female }}
                        </h4>
                        <p class="text-muted mb-0">No. Of Ratings By Female</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <h4 class="cardTitle mb-1 mt-1">
                          {{ ratings_by_others }}
                        </h4>
                        <p class="text-muted mb-0">No. Of Ratings By Others</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>No. Of Ratings Date Wise</h6>
                      <apexchart
                        :options="chart_date_wise_ratings.chartOptions"
                        :series="chart_date_wise_ratings.series"
                        class="apex-charts"
                        dir="ltr"
                        height="300"
                        type="bar"
                      ></apexchart>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Average Ratings Date Wise</h6>
                      <apexchart
                        :options="chart_date_wise_average_ratings.chartOptions"
                        :series="chart_date_wise_average_ratings.series"
                        class="apex-charts"
                        dir="ltr"
                        height="300"
                        type="bar"
                      ></apexchart>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>No. Of Ratings Day Wise</h6>
                      <apexchart
                        :options="chart_day_wise_ratings.chartOptions"
                        :series="chart_day_wise_ratings.series"
                        class="apex-charts"
                        dir="ltr"
                        height="300"
                        type="bar"
                      ></apexchart>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Average Ratings Day Wise</h6>
                      <apexchart
                        :options="chart_day_wise_average_ratings.chartOptions"
                        :series="chart_day_wise_average_ratings.series"
                        class="apex-charts"
                        dir="ltr"
                        height="300"
                        type="bar"
                      ></apexchart>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>No. Of Ratings By Session</h6>
                      <apexchart
                        :options="chart_session_wise_ratings.chartOptions"
                        :series="chart_session_wise_ratings.series"
                        type="pie"
                        width="380"
                      ></apexchart>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <h6>Average Ratings By Session</h6>
                      <apexchart
                        :options="
                          chart_session_wise_average_ratings.chartOptions
                        "
                        :series="chart_session_wise_average_ratings.series"
                        class="apex-charts"
                        dir="ltr"
                        height="300"
                        type="bar"
                      ></apexchart>
                    </div>
                  </div>
                </div>
              </div>
              <!----START OF PRODUCT BY VALUE---->
            </b-tab>

            <b-tab @click="getFeedbackReviewbyflag(1, 0)">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block"> Active Feedback</span>
              </template>
              <div class="row">
                <div class="col-12">
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-4" style="margin-bottom: 15px">
                      <div class="btn-group" role="group">
                        <button
                          class="btn btn-themeBrown"
                          type="button"
                          @click="downloadSample(1)"
                        >
                          Excel
                        </button>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Show &nbsp;&nbsp;
                          <b-form-select
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                            style="margin-left: 5px; margin-right: 5px"
                          ></b-form-select
                          >&nbsp;&nbsp;&nbsp; entries
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-4 row">
                      <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-end"
                      >
                        <label class="d-inline-flex align-items-center">
                          Search:
                          <b-form-input
                            v-model="filter"
                            class="form-control form-control-sm ms-2"
                            placeholder="Search..."
                            type="search"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <!-- Table -->
                  <div class="table-responsive mb-0">
                    <!-- <column-visibility :fields.sync="fields"></column-visibility> -->
                    <b-table
                      :busy="isReportTableBusy"
                      :current-page="currentPage"
                      :fields="fields"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      :items="tableData"
                      :per-page="perPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      bordered
                      fixed
                      fixed-header
                      head-variant="light"
                      outlined
                      responsive="sm"
                      striped
                      @filtered="onFiltered"
                    >
                      <!-- to review hover -->
                      <template v-slot:cell(review)="data">
                        <span :title="data.item.review">{{
                          data.item.review
                        }}</span>
                      </template>

                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                      <template v-slot:head(rating)>
                        <span>Overall Rating</span>
                      </template>
                      <template v-slot:head(repliedDate)>
                        <span>Reply Date</span>
                      </template>
                      <template v-slot:head(datetime)>
                        <span>Review Date</span>
                      </template>
                      <!-- <template v-slot:cell(Reply)="data">
                                                    <span :title="data.item.Reply">{{data.item.Reply}}</span>
                                                </template> -->
                      <template v-slot:cell(Restaurant)="data">
                        <span :title="data.item.Restaurant">{{
                          data.item.Restaurant
                        }}</span>
                      </template>

                      <template v-slot:cell(Reply)="data">
                        <div>
                          <span
                            v-if="
                              data.item.allReplies.length == 0 &&
                                ($storageData.profile.empTypeID == 2 ||
                                  $storageData.profile.empTypeID == 3 ||
                                  $storageData.profile.empTypeID == 11)
                            "
                            style="cursor: pointer; color: #2069f1"
                            @click="giveReply(data.item.FeedbackId)"
                            ><b-button>Reply </b-button></span
                          >
                          <p v-if="data.item.allReplies.length > 0">
                            {{ data.item.allReplies[0].replyText }}
                          </p>
                        </div>

                        <!-- condition for reply -->
                        <!-- <span  v-if="data.item.validity=='Valid'" style="color:green;">{{data.item.validity}}</span>
                    <span  v-else style="color:red;">{{data.item.validity}}</span>  -->
                      </template>
                      <template v-slot:cell(Action)="data">
                        <b-button
                          v-if="
                            (data.item.isActive == 1 &&
                              $storageData.profile.empTypeID == 1) ||
                              $storageData.profile.empTypeID == 11
                          "
                          style="cursor: pointer"
                          variant="danger"
                          @click="muteFeedback(data.item.FeedbackId)"
                          >Block <i class="vsm--icon uil-comment-slash"> </i>
                        </b-button>
                        <b-button
                          v-if="
                            (data.item.isActive == 1 &&
                              $storageData.profile.empTypeID == 3) ||
                              $storageData.profile.empTypeID == 4
                          "
                          style="cursor: pointer"
                          variant="danger"
                          @click="reportFeedback(data.item.FeedbackId)"
                          >Report <i class="vsm--icon uil-comment-slash"> </i>
                        </b-button>
                      </template>

                      <template v-slot:cell(Details)="data">
                        <span>
                          <i
                            class="uil-info-circle"
                            style="
                              font-size: 19px;
                              cursor: pointer;
                              color: #f3766a;
                            "
                            title="View"
                            @click="
                              seeDetailsReply(
                                data.item.FeedbackId,
                                data.item.order_id,
                                data.item.otherRatings,
                                data.item.allReplies,
                                data.item.review,
                                data.item.rating,
                                data.item.Restaurant
                              )
                            "
                          ></i>

                          <!-- <p v-for="item in data.item.allReplies" :key="item.message">
     {{item.replyText | truncate(10, '...')}} <br/>
     <b-button v-if="item.userReply!=null" style="cursor:pointer; " @click="seeDetailsReply(item.replyText,item.userReply);" variant="danger">View Details<i class="vsm--icon uil-comment-slash">  </i>

</b-button>
<b-button v-else-if="item.adminReply!=null" style="cursor:pointer; " @click="seeDetailsReply(item.replyText,item.adminReply);" variant="danger">View Details<i class="vsm--icon uil-comment-slash">  </i>

</b-button>
</p> -->
                        </span>
                        <!-- <span v-else>No Reply Yet</span> -->
                      </template>

                      <template v-slot:cell(Date&Time)="data">
                        <span>{{ data.item.datetime }}</span>
                      </template>
                      <template v-slot:cell(repliedDate)="data">
                        <p v-if="data.item.allReplies.length > 0">
                          {{ data.item.allReplies[0].repliedDate }}
                        </p>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="
                          dataTables_paginate
                          paging_simple_numbers
                          float-end
                        "
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :per-page="perPage"
                            :total-rows="rows"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!----END OF PRODUCT BY VALUE---->
            </b-tab>
            <b-tab @click="getFeedbackReviewbyflag(2, 0)">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block">Reported Feedback</span>
              </template>
              <div class="row">
                <div class="col-12">
                  <div class="row mt-4">
                    <div class="col-sm-12 col-md-4" style="margin-bottom: 15px">
                      <div class="btn-group" role="group">
                        <button
                          class="btn btn-themeBrown"
                          type="button"
                          @click="downloadSample(2)"
                        >
                          Excel
                        </button>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Show &nbsp;&nbsp;
                          <b-form-select
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                            style="margin-left: 5px; margin-right: 5px"
                          ></b-form-select
                          >&nbsp;&nbsp;&nbsp; entries
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-4 row">
                      <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-end"
                      >
                        <label class="d-inline-flex align-items-center">
                          Search:
                          <b-form-input
                            v-model="filter"
                            class="form-control form-control-sm ms-2"
                            placeholder="Search..."
                            type="search"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <!-- Table -->
                  <div class="table-responsive mb-0">
                    <b-table
                      :busy="isReportTableBusy"
                      :current-page="currentPage"
                      :fields="fields"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      :items="tableData"
                      :per-page="perPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      bordered
                      fixed
                      fixed-header
                      head-variant="light"
                      outlined
                      responsive="sm"
                      striped
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(review)="data">
                        <span :title="data.item.review">{{
                          data.item.review
                        }}</span>
                      </template>
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                      <template v-slot:head(rating)>
                        <span>Weightage Rating</span>
                      </template>
                      <template v-slot:head(repliedDate)>
                        <span>Reply Date</span>
                      </template>
                      <template v-slot:head(datetime)>
                        <span>Review Date</span>
                      </template>
                      <!-- <template v-slot:cell(Reply)="data">
                                                    <span :title="data.item.Reply">{{data.item.Reply}}</span>
                                                </template> -->
                      <template v-slot:cell(Restaurant)="data">
                        <span :title="data.item.Restaurant">{{
                          data.item.Restaurant
                        }}</span>
                      </template>

                      <template v-slot:cell(Reply)="data">
                        <!-- <div >
                   <span v-if='data.item.allReplies.length==0' style="cursor:pointer;color:#2069f1;" @click="giveReply(data.item.FeedbackId);"><i class="vsm--icon uil-message">
                 </i></span>
                  <p v-if="data.item.allReplies.length>0" >{{data.item.allReplies[0].replyText}}</p>
                   </div> -->
                        <div>
                          <div
                            v-if="
                              data.item.allReplies.length == 0 &&
                                (login_type =
                                  2 ||
                                  $storageData.profile.empTypeID == 2 ||
                                  $storageData.profile.empTypeID == 3 ||
                                  $storageData.profile.empTypeID == 11)
                            "
                          ></div>
                          <div v-else>
                            <span
                              v-if="
                                data.item.allReplies.length == 0 &&
                                  ($storageData.profile.empTypeID == 2 ||
                                    $storageData.profile.empTypeID == 3 ||
                                    $storageData.profile.empTypeID == 11)
                              "
                              style="cursor: pointer; color: #2069f1"
                              @click="giveReply(data.item.FeedbackId)"
                            >
                              <b-button type="button">Reply</b-button>
                            </span>
                            <p v-if="data.item.allReplies.length > 0">
                              {{ data.item.allReplies[0].replyText }}
                            </p>
                          </div>
                        </div>
                        <!-- <div >
                   <span v-if='data.item.allReplies.length==0 && ($storageData.profile.empTypeID==2||$storageData.profile.empTypeID==3||$storageData.profile.empTypeID==11)' style="cursor:pointer;color:#2069f1;" @click="giveReply(data.item.FeedbackId);"><b-button>Reply
                 </b-button></span>
                  <p v-if="data.item.allReplies.length>0" >{{data.item.allReplies[0].replyText}}</p>
                   </div> -->

                        <!-- condition for reply -->
                        <!-- <span  v-if="data.item.validity=='Valid'" style="color:green;">{{data.item.validity}}</span>
                    <span  v-else style="color:red;">{{data.item.validity}}</span>  -->
                      </template>
                      <template v-slot:cell(Action)="data">
                        <b-button
                          v-if="
                            (data.item.isActive == 2 &&
                              $storageData.profile.empTypeID == 1) ||
                              $storageData.profile.empTypeID == 11
                          "
                          style="cursor: pointer"
                          variant="danger"
                          @click="muteFeedback(data.item.FeedbackId)"
                          >Block <i class="vsm--icon uil-comment-slash"> </i>
                        </b-button>
                      </template>
                      <template v-slot:cell(Details)="data">
                        <span>
                          <i
                            class="uil-info-circle"
                            style="
                              font-size: 19px;
                              cursor: pointer;
                              color: #f3766a;
                            "
                            @click="
                              seeDetailsReply(
                                data.item.FeedbackId,
                                data.item.order_id,
                                data.item.otherRatings,
                                data.item.allReplies,
                                data.item.review,
                                data.item.rating,
                                data.item.Restaurant
                              )
                            "
                          >
                          </i>

                          <!-- <p v-for="item in data.item.allReplies" :key="item.message">
     {{item.replyText | truncate(10, '...')}} <br/>
     <b-button v-if="item.userReply!=null" style="cursor:pointer; " @click="seeDetailsReply(item.replyText,item.userReply);" variant="danger">View Details<i class="vsm--icon uil-comment-slash">  </i>

</b-button>
<b-button v-else-if="item.adminReply!=null" style="cursor:pointer; " @click="seeDetailsReply(item.replyText,item.adminReply);" variant="danger">View Details<i class="vsm--icon uil-comment-slash">  </i>

</b-button>
</p> -->
                        </span>
                      </template>

                      <template v-slot:cell(Date&Time)="data">
                        <span>{{ data.item.datetime }}</span>
                      </template>
                      <template v-slot:cell(repliedDate)="data">
                        <p v-if="data.item.allReplies.length > 0">
                          {{ data.item.allReplies[0].repliedDate }}
                        </p>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="
                          dataTables_paginate
                          paging_simple_numbers
                          float-end
                        "
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :per-page="perPage"
                            :total-rows="rows"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!----END OF PRODUCT BY VALUE---->
            </b-tab>

            <b-tab @click="getFeedbackReviewbyflag(0, 0)">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="far fa-user"></i>
                </span>
                <span class="d-none d-sm-inline-block">Blocked Feedback</span>
              </template>
              <div class="row">
                <div class="col-12">
                  <div class="row mt-4">
                    <div
                      v-if="login_type == 1"
                      class="col-sm-12 col-md-4"
                      style="margin-bottom: 15px;"
                    >
                      <div class="btn-group" role="group">
                        <button
                          class="btn btn-themeBrown"
                          type="button"
                          @click="downloadSample(0)"
                        >
                          Excel
                        </button>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-4">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Show &nbsp;&nbsp;
                          <b-form-select
                            v-model="perPage"
                            :options="pageOptions"
                            size="sm"
                            style="margin-left: 5px; margin-right: 5px"
                          ></b-form-select
                          >&nbsp;&nbsp;&nbsp; entries
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-4 row">
                      <div
                        id="tickets-table_filter"
                        class="dataTables_filter text-md-end"
                      >
                        <label class="d-inline-flex align-items-center">
                          Search:
                          <b-form-input
                            v-model="filter"
                            class="form-control form-control-sm ms-2"
                            placeholder="Search..."
                            type="search"
                          ></b-form-input>
                        </label>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                  <!-- Table -->
                  <div class="table-responsive mb-0">
                    <b-table
                      :busy="isReportTableBusy"
                      :current-page="currentPage"
                      :fields="fields"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      :items="tableData"
                      :per-page="perPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      bordered
                      fixed
                      fixed-header
                      head-variant="light"
                      outlined
                      responsive="sm"
                      striped
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(review)="data">
                        <span :title="data.item.review">{{
                          data.item.review
                        }}</span>
                      </template>
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>

                      <template v-slot:head(rating)>
                        <span>Weightage Rating</span>
                      </template>
                      <template v-slot:head(repliedDate)>
                        <span>Reply Date</span>
                      </template>
                      <template v-slot:head(datetime)>
                        <span>Review Date</span>
                      </template>
                      <!-- <template v-slot:cell(Reply)="data">
                                                    <span :title="data.item.Reply">{{data.item.Reply}}</span>
                                                </template> -->
                      <template v-slot:cell(Restaurant)="data">
                        <span :title="data.item.Restaurant">{{
                          data.item.Restaurant
                        }}</span>
                      </template>

                      <template v-slot:cell(Reply)="data">
                        <!-- <div >
                   <span v-if='data.item.allReplies.length==0' style="cursor:pointer;color:#2069f1;" @click="giveReply(data.item.FeedbackId);"><i class="vsm--icon uil-message">
                 </i></span>
                  <p v-if="data.item.allReplies.length>0" >{{data.item.allReplies[0].replyText}}</p>
                   </div> -->
                        <div>
                          <!-- <div v-if="login_type = 2"></div> -->
                          <div
                            v-if="
                              data.item.allReplies.length == 0 &&
                                (login_type =
                                  2 ||
                                  $storageData.profile.empTypeID == 2 ||
                                  $storageData.profile.empTypeID == 3 ||
                                  $storageData.profile.empTypeID == 11)
                            "
                          ></div>
                          <div v-else>
                            <span
                              v-if="
                                data.item.allReplies.length == 0 &&
                                  ($storageData.profile.empTypeID == 2 ||
                                    $storageData.profile.empTypeID == 3 ||
                                    $storageData.profile.empTypeID == 11)
                              "
                              style="cursor: pointer; color: #2069f1"
                              @click="giveReply(data.item.FeedbackId)"
                            >
                              <b-button type="button">Reply</b-button>
                            </span>
                            <p v-if="data.item.allReplies.length > 0">
                              {{ data.item.allReplies[0].replyText }}
                            </p>
                          </div>
                        </div>

                        <!-- condition for reply -->
                        <!-- <span  v-if="data.item.validity=='Valid'" style="color:green;">{{data.item.validity}}</span>
                    <span  v-else style="color:red;">{{data.item.validity}}</span>  -->
                      </template>
                      <template v-slot:cell(Action)="data">
                        <b-button
                          v-if="
                            (data.item.isActive == 1 &&
                              $storageData.profile.empTypeID == 1) ||
                              $storageData.profile.empTypeID == 11
                          "
                          style="cursor: pointer"
                          variant="danger"
                          @click="muteFeedback(data.item.FeedbackId)"
                          >Block <i class="vsm--icon uil-comment-slash"> </i>
                        </b-button>
                        <b-button
                          v-if="
                            (data.item.isActive == 0 &&
                              $storageData.profile.empTypeID == 1) ||
                              $storageData.profile.empTypeID == 11
                          "
                          style="cursor: pointer"
                          variant="warning"
                          @click="unMuteFeedback(data.item.FeedbackId)"
                          >Unblock <i class="vsm--icon uil-comment-verify"> </i
                        ></b-button>
                      </template>
                      <template v-slot:cell(Details)="data">
                        <span>
                          <i
                            class="uil-info-circle"
                            style="
                              font-size: 19px;
                              cursor: pointer;
                              color: #f3766a;
                            "
                            @click="
                              seeDetailsReply(
                                data.item.FeedbackId,
                                data.item.order_id,
                                data.item.otherRatings,
                                data.item.allReplies,
                                data.item.review,
                                data.item.rating,
                                data.item.Restaurant
                              )
                            "
                          >
                          </i>
                        </span>
                      </template>
                      <template v-slot:cell(Date&Time)="data">
                        <span>{{ data.item.datetime }}</span>
                      </template>
                      <template v-slot:cell(repliedDate)="data">
                        <p v-if="data.item.allReplies.length > 0">
                          {{ data.item.allReplies[0].repliedDate }}
                        </p>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="
                          dataTables_paginate
                          paging_simple_numbers
                          float-end
                        "
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :per-page="perPage"
                            :total-rows="rows"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!----END OF PRODUCT BY VALUE---->
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    <b-modal id="modal-lg-reply" centered size="lg"
      ><div class="container deliveryAddContainer">
        <h4>Add Reply</h4>
        <!-- <input class="form-control" type="text"  v-model="replyField" placeholder="Type Your Reply"> -->
        <textarea
          v-model="replyField"
          :class="{
            'is-invalid': submitted && $v.replyField.$error
          }"
          :disabled="isEdit"
          class="form-control"
          cols="50"
          placeholder="Type Your Reply"
          rows="4"
          track-by="replyField"
          type="text"
        ></textarea>
        <div v-if="submitted && $v.replyField.$error" class="invalid-feedback">
          <span v-if="!$v.replyField.required">This value is required.</span>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-spinner
            v-if="loadingtable"
            class="m-2 col-3"
            role="status"
            variant="primary"
          ></b-spinner>
          <b-button
            class="float-left btnSave btn btn-warning"
            @click="saveReply"
          >
            <span> Submit </span>
          </b-button>
          <!-- / footer Btn end -->
        </div>
      </template>
    </b-modal>
    <b-modal id="modal-lg-repliedDetails" centered hide-footer size="md">
      <div class="container">
        <div class="feedBackMain">
          <div class="row">
            <h4 class="setionTitle">User Feedback</h4>
            <br />
            <p>
              Restaurant Name:
              <b>{{ this.restNAme }}</b>
            </p>
            <br />
            <div v-if="reviewed_questions.length > 0">
              <div
                v-for="(questions, index) in reviewed_questions"
                v-bind:key="questions"
                class="col-md-12 mb-3"
              >
                <!-- {{ questions }} -->
                <!-- <h6 v-if="questions==''">No Replies Given By User</h6>-->
                <div class="questTitle">
                  <span>{{ index + 1 }}. </span>{{ questions.question }}
                </div>
                <div class="icon_align">
                  <span v-for="index in 5" :key="index">
                    <i
                      v-if="index <= parseInt(questions.rating)"
                      class="fas fa-star fa-2x"
                      style="margin-right: 5px; color: #ffc60c; font-size: 22px"
                    ></i>
                    <i
                      v-else
                      class="fas fa-star fa-2x"
                      style="margin-right: 5px; color: #dadada; font-size: 22px"
                    ></i>
                  </span>
                  <br />
                  <span v-if="questions.reason!=null && questions.reason.length > 0" class="text-danger">
                    Reason : {{ questions.reason }}
                  </span>
                </div>
              </div>
            </div>
            <div v-else class="col-md-12 mb-2">
              <h6>No Rating Given by User</h6>
            </div>
          </div>
          <!-- rating row end -->
          <div class="row">
            <div class="col-md-12">
              <div class="questTitle align-left">Comments</div>
              <textarea
                v-model="this.reviewtxt"
                class="form-control"
                cols="50"
                disabled
                placeholder="Your suggestion helps others to make better choices."
                rows="2"
              ></textarea>
            </div>
          </div>
          <!-- Comments end -->
        </div>
        <hr style="color: #e5e5e5" />
        <div v-if="this.repliedTextArr.length > 0" class="questTitle">
          Replies :

          <div
            v-for="item in this.repliedTextArr"
            :key="item.message"
            class="row questTitle"
            style="border-left: 5px solid #f3766a"
          >
            <div class="col-md-12">
              <!-- <span v-if="item.Restaurant!=null"> -->
              <div class="questTitle">
                <span style="font-size: 15px; color: #b3b3b3">Reply From,</span>
                <span style="float: right; color: #b3b3b3">{{
                  item.repliedDate
                }}</span
                ><br />
                <!-- <span style="font-size:14px;">{{item.Restaurant}}</span> -->
                <!-- <span v-if="item.userReply!=null">{{item.userReply}}</span> -->
                <span v-if="item.adminReply != null">{{
                  item.adminReply
                }}</span>
                <br />
                <span style="white-space: break-spaces">{{
                  item.replyText
                }}</span>
              </div>
              <!-- </span> -->
              <!-- <span v-if="item.Restaurant!=null"><div class="questTitle">Reply From : {{item.Restaurant}}</div></span> -->
            </div>
          </div>
        </div>

        <!-- <div v-if="this.repliedTextArr.length>0" class="questTitle">Replies :</div>
                <div class="row questTitle" v-for="item in this.repliedTextArr" :key="item.message">
                  <div class="col-md-12">{{item.replyText}}
                     <br><span v-if="item.Restaurant!=null"><div class="questTitle">Reply From : {{item.Restaurant}}</div></span>
                    <span v-if="item.Restaurant!=null"><div class="questTitle">Reply From : {{item.Restaurant}}</div></span>
                  </div>
                </div> -->
      </div>
    </b-modal>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import { authHeader } from "../../../helpers/fakebackend/auth-header";
import { required } from "vuelidate/lib/validators";

export default {
  page: {
    title: "Feedback Mix Report",
    meta: [
      {
        name: "description",
        content: appConfig.description
      }
    ]
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker
  },
  data() {
    return {
      login_type:0,
      tableData: [],
      tableExcelData: [],
      tableData2: [],
      title: "Feedback Mix Report",
      items: [
        {
          text: "View"
        },
        {
          text: "Feedback Mix Report",
          active: true
        }
      ],
      excelFields: {},
      restaurants: [],
      branches: [],
      cityArr: [],
      city: "",
      topSellingProducts: [],
      daterange: [],
      countryArr: [],
      orderData: [],
      orderDetails: [],
      country: "",
      brandArr: [],
      brandData: "",
      restBranchID: this.$storageData.profile.restBranchID,
      reviewText: "",
      tabflag: "",
      isReportTableBusy: false,
      replyField: "",
      feedbackIdhold: "",
      repliedTextArr: [],
      otherRatingsArr: [],
      ratingbyuser: "",
      series: [],
      repliedText: "",
      repliedBy: "",
      submitted: false,
      restaurantID: "",
      branchID: "",
      companyArr: [],
      companyID: 0,
      loadingtable: false,
      ratingQ: 5,
      restNAme: "",
      sortBy: "age",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter: null,
      filterOn: [],
      allreliesreplier: [],
      sortDesc: false,
      reviewtxt: "",
      reviewed_questions: [],
      fields: [
        {
          key: "Details",
          sortable: true
        },
        {
          key: "orderId",
          sortable: true
        },

        {
          key: "Restaurant",
          sortable: true
        },

        {
          key: "userName",
          label: "Customer Name",
          sortable: true
        },
        {
          key: "review",
          sortable: true
        },
        {
          key: "rating",
          label: "Overall Rating",
          sortable: true,
          tdClass: "align-center"
        },
        {
          key: "session",
          label: "Session",
          sortable: true,
          tdClass: "align-center"
        },
        {
          key: "day",
          label: "Day",
          sortable: true,
          tdClass: "align-center"
        },
        {
          key: "Reply",
          sortable: false
        },

        {
          key: "datetime",
          sortable: true
        },
        {
          key: "repliedDate",
          sortable: true
        },
        {
          key: "Action",
          sortable: false
        }
      ],

      // ////////// datatable 3 category //////////////////

      // datatable 1 & 2 ///////
      sortBy2: "age",
      totalRows2: 1,
      currentPage2: 1,
      perPage2: 10,
      pageOptions2: [10, 25, 50, 100, 500, 1000, 2000, "All"],
      filter2: null,
      filterOn2: [],
      sortDesc2: false,
      fields2: [
        {
          key: "category",
          sortable: true
        },
        {
          key: "orders",
          sortable: true
        },

        {
          key: "totalItemPrice",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "totalItemTax",
          sortable: true,
          tdClass: "align-right"
        },
        {
          key: "totalRevenue",
          sortable: true,
          tdClass: "align-right"
        }
      ],
      principleBranchID: "",
      principleBranches: [],
      corpBranchID: "",
      corpBranches: [],
      restBranchArr: [],
      daysOfWeek: [
        { key: "Monday", val: 1 },
        { key: "Tuesday", val: 2 },
        { key: "Wednesday", val: 3 },
        { key: "Thursday", val: 4 },
        { key: "Friday", val: 5 },
        { key: "Saturday", val: 6 },
        { key: "Sunday", val: 7 }
      ],
      day: "",
      sessions: ["BreakFast", "Lunch", "Snacks", "Dinner"],
      session: "",
      isEdit: false,
      total_ratings: 0,
      average_ratings: 0,
      total_reviews: 0,
      ratings_equal_above_three: 0,
      ratings_below_three: 0,
      ratings_by_male: 0,
      ratings_by_female: 0,
      ratings_by_others: 0,
      chart_date_wise_ratings: [],
      chart_date_wise_average_ratings: [],
      chart_day_wise_ratings: [],
      chart_day_wise_average_ratings: [],
      chart_session_wise_ratings: [],
      chart_session_wise_average_ratings: []
    };
  },
  validations: {
    replyField: {
      required
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },

    rows2() {
      return this.tableData2.length;
    }
  },
  mounted() {
    this.login_type=this.$storageData.login_type;
    // Set the initial number of items
    this.totalRows = this.items.length;
    // this.readRestaurantMenu();
    // this.getRestaurantList();

    if ( this.$storageData.profile.empTypeID == 2 ||
        this.$storageData.profile.empTypeID == 11) {
      this.getRestaurantCountry();
    }
    else
    {
      this.getAllCountry();
    }
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    }
  },
  methods: {
    /**
     * Search the table data with search input
     */
    downloadSample(flag) {
      if (this.principleBranchID.length <= 0 && this.corpBranchID.length <= 0) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Please select either principle branch or corporate branch."
        });
        return;
      } else if (
        this.principleBranchID.length > 0 &&
        this.corpBranchID.length > 0
      ) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "You can select either principle branch or corporate branch."
        });
        return;
      } else if (this.restBranchID.length <= 0) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Please select the restaurant branch."
        });
      } else {
        this.isReportTableBusy = true;

        this.tabflag = flag;
        // alert();
        this.axios
          .post(this.$loggedRole+"/v2/downloadFeedbackReport", {
            restBranchId: this.restBranchID
              ? this.restBranchID.restBranchID
              : "",
            daterange: this.daterange,
            flag: this.tabflag,
            principle_branch_id: this.principleBranchID
              ? this.principleBranchID.principleBranchID
              : "",
            corporate_branch_id: this.corpBranchID
              ? this.corpBranchID.corpBranchID
              : "",
            day_session: this.session ? this.session : "",
            day: this.day ? this.day.val : ""
          })
          .then(response => {
            this.excelFields = {};
            this.tableExcelData = response.data.data;
            this.axios
              .post(
                "exportExcel",
                {
                  exportData: this.tableExcelData,
                  page: "Feedback Mix Report"
                },
                {
                  responseType: "blob"
                }
              )
              .then(response => {
                this.isReportTableBusy = false;
                const url = URL.createObjectURL(
                  new Blob([response.data], {
                    type: "application/vnd.ms-excel"
                  })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "Feedback-Mix-Report.xlsx");
                document.body.appendChild(link);
                link.click();
              })
              .catch(error => {
                this.isReportTableBusy = false;
                 this.$swal({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message
            });
              });
          })
          .catch(error => {
            this.isReportTableBusy = false;
             this.$swal({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message
            });
          });
      }
    },

    emojiImg(index) {
      // let emojiClass = '';
      switch (index) {
        case 1:
          return "fas fa-angry";
        case 2:
          return "fas fa-frown-open";
        case 3:
          return "fas fa-meh";
        case 4:
          return "fas fa-smile";
        case 5:
          return "fas fa-grin-hearts";
        default:
          // console.warn('not configured`);
          break;
      }
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllCountry() {
      this.countryArr = [];
      this.country = "";
      this.axios.post(this.$loggedRole+"/getAllCountry").then(response => {
        this.countryArr = response.data.data;
        // if (this.$storageData.profile.empTypeID == 1) {
        //    // super admin
        //    // this.getRestaurantBrands();
        //    // this.getAllCompanies();
        //  }
        if (this.$storageData.profile.empTypeID == 11) {
          // food company login
          this.getAllCompanies();
          this.getRestaurantBrandsByCompanyID();
        } else {
          // this.readRestaurantBranchData();
        }
      });
    },
    getAllCities() {
      this.cityArr = [];
      this.city = "";
      let corporate_branch = 0;
      let corporate_id = 0;
      if (this.$storageData.profile.empTypeID == 5) {
        corporate_id = this.$storageData.profile.corporateID;
      }
      if (this.$storageData.profile.empTypeID == 6) {
        corporate_id = this.$storageData.profile.corporateID;
        corporate_branch = this.$storageData.profile.corpBranchID;
      }
      let formData = new FormData();
      formData.append("country", this.country ? this.country.country : "");
      formData.append("corporate_id", corporate_id);
      formData.append("corporate_branch", corporate_branch);
      formData.append("empTypeID", this.$storageData.profile.empTypeID);
      formData.append("empID", this.$storageData.profile.pid);
      this.axios.post(this.$loggedRole+"/v2/getAllCities", formData).then(response => {
        this.cityArr = response.data.data;
        //this.city = (this.cityArr) ? this.cityArr[0] : "";
        if (this.$storageData.profile.empTypeID == 1) {
          // super admin
          // this.getRestaurantBrands();
          this.getAllCompanies();
        }
        if (this.$storageData.profile.empTypeID == 11) {
          // food company login
          this.getAllCompanies();
          this.getRestaurantBrandsByCompanyID();
        } else {
          this.readRestaurantBranchData();
        }
      });
    },

    onFiltered2(filteredItems2) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows2 = filteredItems2.length;
      this.currentPage2 = 1;
    },
    getRestaurantBrands() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantList", {
          restID: this.$storageData.profile.restID,
          restBranchID: this.$storageData.profile.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID
        })
        .then(result => {
          this.brandArr = result.data.data;
          this.brandData = this.brandArr ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },
    getRestaurantBrandsByCompanyID() {
      var companyID = this.companyID;
      if (this.$storageData.profile.empTypeID == 11) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", { companyID: companyID })
        .then(response => {
          this.brandArr = response.data.data;
          // this.brandData = (this.brandArr) ? this.brandArr[0] : "";
          this.readRestaurantBranchData();
        });
    },
    getRestaurantCountry() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantCountry", {
          restID: this.$storageData.profile.restID,
          company_id:this.$storageData.profile.companyID
        })
        .then(response => {
          this.countryArr = response.data.data;
          this.country = this.countryArr ? this.countryArr[0] : "";

          // this.getAllCompanies();
          this.getRestaurantCities();
        });
    },
    getRestaurantCities() {
      var restID = this.brandData.restID;
      if (this.$storageData.profile.empTypeID == 11) {
        restID = this.$storageData.profile.restID;
      }
      this.axios
        .post(this.$loggedRole+"/getRestaurantCities", {
          restID: restID,
          country: this.country ? this.country.country : "",
          company_id:this.$storageData.profile.companyID
        })
        .then(response => {
          this.cityArr = response.data.data;
          // if (this.$storageData.profile.empTypeID == 11) {
          //   this.getRestaurantBrandsByCompanyID();
          // } else {
          //   this.readRestaurantBranchData();
          // }
        });
    },
    getAllCompanies() {
      this.companyArr = [];
      this.companyID = "";
      var companyID = 0;
      if (this.$storageData.profile.empTypeID == 11) {
        companyID = this.$storageData.profile.companyID;
      }
      this.axios
        .post(this.$loggedRole+"/getAllCompanies", { companyID: companyID })
        .then(response => {
          this.companyArr = response.data.data;
          if (this.$storageData.profile.empTypeID == 11) {
            this.companyID = this.companyArr ? this.companyArr[0] : "";
          }
          //  this.branchID = (this.branches) ? this.branches[0] :"";
          //  this.restBranchID = (this.branchID) ? this.branchID.restBranchID: "";
          //  this.getProductMixReport();
        });
    },
    readRestaurantBranchData() {
      this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {
          restID:
            this.brandData != ""
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          restBranchID: 0,
          empTypeID: this.$storageData.profile.empTypeID,
          city: this.city ? this.city.city : ""
        })
        .then(response => {
          this.branches = response.data.data;
          this.branchID = this.branches ? this.branches[0] : "";
          this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
          //this.getProductMixReport();
        });
    },
    onChangeRestBrand() {
      this.readRestaurantBranchData();
      this.getRestaurantCities();
      //  if(this.restaurantID.restID>0){ /// for maintaining state of dropdown
      //    sessionStorage.setItem('restID',this.restaurantID.restID);
      //    sessionStorage.setItem('restName',this.restaurantID.name);
      // }
      // if(this.branchID.restBranchID>0){ /// for maintaining state of dropdown
      //    sessionStorage.setItem('branchID',this.branchID.restBranchID);
      //    sessionStorage.setItem('branchName',this.branchID.branchName);
      // }
    },
    onchangeBranch() {
      this.restBranchID = this.branchID ? this.branchID.restBranchID : "";
      //this.getProductMixReport();
    },

    applyFilter() {
      if (this.daterange.includes(null)) {
        this.daterange = [];
      }
      this.getFeedbackReviewbyflag(3, 0);
      // this.getProductMixReport();
      // this.getFeedbackReviewbyflag(this.tabflag, 0);
      // this.getProductMixReportByValue(this.productMixTab);
      // this.getProductMixReportByCategory();
    },

    clearFilter() {
      this.daterange = [];
      this.branches = [];
      this.brandData = "";
      this.restBranchID = "";
      this.country = "";
      this.companyID = "";
      this.brandData = "";
      this.city = "";
      this.branchID = "";
      this.paymentType = "";
      this.orderType = "";
      this.principleBranches = this.corpBranches = [];
      this.day = this.session = this.principleBranchID = this.corpBranchID = "";
      // this.getProductMixReport();
      // this.getFeedbackReviewbyflag(this.tabflag,0);
    },

    getProductMixReport() {
      this.axios
        .post(this.$loggedRole+"/feedbackMixReport", {
          restID:
            this.brandData != ""
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          restBranchID: this.restBranchID,
          empTypeID: this.$storageData.profile.empTypeID,
          daterange: this.daterange
        })
        .then(result => {
          this.topSellingProducts = result.data.data;
          // this.viewSalesReport();
          // this.getFeedbackReview();
        });
    },

    viewSalesReport() {
      this.axios
        .post(this.$loggedRole+"/viewSalesReportRating", {
          restID:
            this.brandData != ""
              ? this.brandData.restID
              : this.$storageData.profile.restID,
          restBranchID: this.restBranchID,
          daterange: this.daterange,
          paymentType: this.paymentType,
          orderType: this.orderType
        })
        .then(response => {
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.data;
          this.cardData = response.data.cardData;
          //  this.salesReportGraph();
          //console.log((response));
        });
    },
    getProductMixReportByValue(flag) {
      // this.tabflag=flag;
      // alert(this.tabflag);
      this.axios
        .post(this.$loggedRole+"/feedbackMixReport",
          {
            restID:
              this.brandData != ""
                ? this.brandData.restID
                : this.$storageData.profile.restID,
            restBranchID: this.restBranchID,
            empTypeID: this.$storageData.profile.empTypeID,
            daterange: this.daterange,
            flag: flag
          } //this.branchID.restBranchID}
        )
        .then(response => {
          //Then injecting the result to datatable parameters.

          this.tableData = response.data.data;
          //console.log((response));
        });
    },
    getProductMixReportByCategory() {
      var restBID =
        this.branchID != "" &&
        this.branchID != null &&
        this.branchID != undefined
          ? this.branchID.restBranchID
          : this.$storageData.profile.restBranchID;
      this.axios
        .post(this.$loggedRole+"/feedbackMixReport",
          {
            restID:
              this.brandData != ""
                ? this.brandData.restID
                : this.$storageData.profile.restID,
            restBranchID: restBID,
            empTypeID: this.$storageData.profile.empTypeID,
            daterange: this.daterange
          } //this.branchID.restBranchID}
        )
        .then(response => {
          //Then injecting the result to datatable parameters.

          this.tableData2 = response.data.data;
          //console.log((response));
        });
    },

    giveReply(feedbackId) {
      this.feedbackIdhold = feedbackId;
      this.$root.$emit("bv::show::modal", "modal-lg-reply");
      // this.getFeedbackReviewbyflag();
    },

    seeDetailsReply(
      feedbackId,
      orderId,
      otherRatings,
      allReplies,
      review,
      rating,
      restaurantName
    ) {
      let formData = new FormData();
      formData.append("feedbackID", feedbackId);
      formData.append("order_id", orderId);
      this.axios
        .post(this.$loggedRole+"/v2/get-review-detail", formData)
        .then(result => {
          this.reviewed_questions = result.data.data;
          this.reviewtxt = review;
          this.otherRatingsArr = otherRatings;
          this.repliedTextArr = allReplies;
          this.ratingbyuser = rating;
          this.ratingQ = otherRatings.length + 1;
          this.restNAme = restaurantName;
          //  this.repliedBy=repliedby;
          this.$root.$emit("bv::show::modal", "modal-lg-repliedDetails");
        })
        .catch(error => {
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message
          });
        });
    },
    muteFeedback(feedbackId) {
      var result = confirm("Want to Block?");
      if (result) {
        let formData = new FormData();
        formData.append("feedbackID", feedbackId);
        this.axios
          .post(this.$loggedRole+"/muteFback", formData, {
            headers: authHeader()
          })
          .then(result => {
            //  console.log(result);
            this.status = result.data.data;
            this.showDismissibleAlertMutUnmut = true;
            location.reload();
          })
          .catch(error => {
            this.loading = false;
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message
            });
          });
      }
    },

    unMuteFeedback(feedbackId) {
      let formData = new FormData();
      formData.append("feedbackID", feedbackId);
      this.axios
        .post(this.$loggedRole+"/unmuteFback", formData, {
          headers: authHeader()
        })
        .then(result => {
          //console.log(result);
          this.status = result.data.data;
          this.showDismissibleAlertMutUnmut = true;
          location.reload();
        })
        .catch(error => {
          this.loading = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message
          });
        });
    },

    reportFeedback(feedbackId) {
      var result = confirm("Are Really Sure Want to Report?");
      if (result) {
        let formData = new FormData();
        formData.append("feedbackID", feedbackId);
        this.axios
          .post(this.$loggedRole+"/reportFback", formData, {
            headers: authHeader()
          })
          .then(result => {
            //  console.log(result);
            this.status = result.data.data;
            this.showDismissibleAlertMutUnmut = true;
            location.reload();
          })
          .catch(error => {
            this.loading = false;
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message
            });
          
          });
      }
    },

    saveReply() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        window.scrollTo(0, 0);
        this.replyField = "";
        return;
      } else {
        this.loading = true;
        let formData = new FormData();

        formData.append("feedbackID", this.feedbackIdhold);
        formData.append("replyText", this.replyField);
        formData.append("createdBy", this.$storageData.profile.pid);
        formData.append("loginTypeID", this.$storageData.login_type);
        this.axios
          .post(this.$loggedRole+"/addReply", formData, {
            headers: authHeader()
          })
          .then(result => {
            this.replyField = "";
            this.feedbackIdhold = "";

            this.status = result.data.data;

            this.showDismissibleAlert = true;
            this.loading = false;
            this.$root.$emit("bv::hide::modal", "modal-lg-reply");
            // setTimeout(function() {
            //   location.reload();
            // },2000);
            this.getFeedbackReviewbyflag(this.tabflag, 0);
            //  location.reload();

            // this.orderItemAddonArr = result.data.orderItemAddonArr;
          })
          .catch(error => {
            this.loading = false;
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message
            });
            
          });
      }
    },
    getFeedbackReview() {
      this.loadingtable = true;
      // alert();
      this.axios
        .post(this.$loggedRole+"/getFdbackReview", {
          restBranchId: this.restBranchID,
          daterange: this.daterange,
          flag: this.tabflag
        })
        .then(response => {
          this.loadingtable = false;
          this.tableData = response.data.data;
          this.tableExcelData = response.data.excelData;
          this.averageRating = response.data.Avg;
          //alert(this.tableData);
        });
    },

    getFeedbackReviewbyflag(flag, exportFlag) {
      if (this.$storageData.login_type == 3 && this.corpBranchID.length <= 0) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Please select the corporate branch."
        });
        return;
      }
      if (
        this.$storageData.login_type != 3 &&
        this.principleBranchID.length <= 0 &&
        this.corpBranchID.length <= 0
      ) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Please select either principle branch or corporate branch."
        });
        return;
      }
      if (
        this.$storageData.login_type != 3 &&
        this.principleBranchID.length > 0 &&
        this.corpBranchID.length > 0
      ) {
        this.$swal({
          icon: "error",
          title: "Oops...",
          text: "You can either select principle branch or corporate branch."
        });

        return;
      } else {
        exportFlag == 0
          ? (this.isReportTableBusy = true)
          : (this.isReportTableBusy = false);
        this.tabflag = flag;
        // alert();
        this.axios
          .post(this.$loggedRole+"/v2/getFeedbackReview", {
            restBranchId: this.restBranchID
              ? this.restBranchID.restBranchID
              : "",
            daterange: this.daterange,
            flag: this.tabflag,
            exportFlag: exportFlag,
            principle_branch_id: this.principleBranchID
              ? this.principleBranchID.principleBranchID
              : "",
            corporate_branch_id: this.corpBranchID
              ? this.corpBranchID.corpBranchID
              : "",
            day_session: this.session ? this.session : "",
            day: this.day ? this.day.val : ""
          })
          .then(response => {
            this.excelFields = {};
            this.isReportTableBusy = false;
            if (this.tabflag == 3) {
              this.total_ratings = response.data.data.total_ratings;
              this.average_ratings = response.data.data.average_ratings;
              this.total_reviews = response.data.data.total_reviews;
              this.ratings_equal_above_three =
                response.data.data.ratings_equal_above_three;
              this.ratings_below_three = response.data.data.ratings_below_three;
              this.ratings_by_male = response.data.data.ratings_by_male;
              this.ratings_by_female = response.data.data.ratings_by_female;
              this.ratings_by_others = response.data.data.ratings_by_others;
              this.chart_date_wise_ratings =
                response.data.data.chart_date_wise_ratings;
              this.chart_date_wise_average_ratings =
                response.data.data.chart_date_wise_average_ratings;
              this.chart_day_wise_ratings =
                response.data.data.chart_day_wise_ratings;
              this.chart_day_wise_average_ratings =
                response.data.data.chart_day_wise_average_ratings;
              this.chart_session_wise_ratings =
                response.data.data.chart_session_wise_ratings;
              this.chart_session_wise_average_ratings =
                response.data.data.chart_session_wise_average_ratings;
            } else {
              this.tableData = response.data.data;
            }
          })
          .catch(error => {
            this.isReportTableBusy = false;
             this.$swal({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message
            });
          });
      }
    },
    getPrincipleAndCorporates() {
      if (this.$storageData.login_type != 3) {
        this.getPrincipleBranches();
      }
      this.getCorporateBranches();
    },
    getPrincipleBranches() {
      this.axios
        .post(this.$loggedRole+"/getPrincipleBranchByPrincipleID", {
          city: this.city ? this.city.city : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.principleAdminID,
          isDashboard: 1, // for all dropdown in only dashboards
          loginTypeID: this.$storageData.login_type
        })
        .then(response => {
          this.principleBranches = response.data.data;
        })
        .catch(error => {
           this.$swal({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message
            });
        });
    },
    getCorporateBranches() {
      let corporate_branch = 0;
      let corporate_id = 0;
      if (this.$storageData.profile.empTypeID == 5) {
        corporate_id = this.$storageData.profile.corporateID;
      }
      if (this.$storageData.profile.empTypeID == 6) {
        corporate_id = this.$storageData.profile.corporateID;
        corporate_branch = this.$storageData.profile.corpBranchID;
      }
      this.axios
        .post(this.$loggedRole+"/feedback-template/get-corporate-branches", {
          city: this.city ? this.city.city : "",
          empTypeID: this.$storageData.profile.empTypeID,
          empID: this.$storageData.profile.pid,
          isDashboard: 1, // for all dropdown in only dashboards
          loginTypeID: this.$storageData.login_type,
          corporate_branch: corporate_branch,
          corporate_id: corporate_id
        })
        .then(response => {
          this.corpBranches = response.data.data;
        })
        .catch(error => {
           this.$swal({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message
            });
        });
    },
    getRestaurantByPrincipleBranch() {
      if(this.principleBranchID) {
        this.axios
            .post(this.$loggedRole + "/feedback-template/principle/restaurants", {
              city: this.city ? this.city.city : "",
              principleBranchID: this.principleBranchID
                  ? this.principleBranchID.principleBranchID
                  : "",
              empTypeID: this.$storageData.profile.empTypeID,
              empID: this.$storageData.profile.principleAdminID,
              loginTypeID: this.$storageData.login_type,
              company_id: this.$storageData.profile.companyID
            })
            .then(response => {
              this.restBranchArr = response.data.data;
            })
            .catch(error => {
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: error.response.data.message
              });
            });
      }
    },
    getRestaurantByCorporateBranch() {
      if (this.corpBranchID) {
        this.axios
          .post(this.$loggedRole+"/feedback-template/corporate/restaurants", {
            city: this.city ? this.city.city : "",
            corpBranchID: this.corpBranchID
              ? this.corpBranchID.corpBranchID
              : "",
            empTypeID: this.$storageData.profile.empTypeID,
            empID: this.$storageData.profile.pid,
            loginTypeID: this.$storageData.login_type,
            company_id:this.$storageData.profile.companyID
          })
          .then(response => {
            this.restBranchArr = response.data.data;
          })
          .catch(error => {
             this.$swal({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message
            });
          });
      }
    }
  },
  middleware: "authentication"
};
</script>
<style scoped>
/** or use this class: table thead th.bTableThStyle { ... } */
textarea {
  resize: none;
}
.icon_align {
  margin-left: 15px;
}
.table .bTableThStyle {
  max-width: 12rem !important;
  text-overflow: ellipsis !important;
  background: #000;
}
.headerDiv {
  margin-bottom: 15px;
}
.setionTitle {
  border-bottom: none;
  text-align: center;
  color: #663333;
  font-size: 22px;
  margin-bottom: 10px;
}
.deliverCard {
  max-height: 95px;
  padding: 7px 8px;
  background: rgb(254, 241, 240);
}
.deliverCard figure {
  float: left !important;
  margin-right: 11px !important;
}
.restaurantCard figure {
  width: 50px;
  height: 50px;
  overflow: hidden;
  /* position: absolute; */
  margin: 0;
  /* left: 20px;
      top: 12px; */
  border: 1px solid #fff;
  border-radius: 3px;
}
.deliveryCardDiv {
  margin-top: 2px;
}
.deliverH3 {
  font-size: 14px;
  color: #663333;
  margin-bottom: 10px !important;
}
.questTitle {
  font-size: 14px;
  color: #663333;
  margin-bottom: 13px;
}
.submit-button {
  margin-top: 24px;
  margin-right: auto;
  margin-left: auto;
  display: block;
}
.submit-button a {
  width: 302px !important;
}
.btn-danger {
  line-height: 1;
}
.btn-warning {
  line-height: 1;
}
/* .modal-header{
  border-bottom:1px solid #ffffff;
} */
</style>
